import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import styles from './Location.module.scss'

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 43.302012, lng: 17.008669 }}
  >
    <Marker position={{ lat: 43.302012, lng: 17.008669 }} />
  </GoogleMap>
))

class Location extends Component {
  render() {
    return (
      <StaticQuery
        query={
          graphql`
            query {
              locationImage: file(relativePath: { eq: "location.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `
        }
        render={data => (
          <section id="location" className={styles.locationContainer}>
            <div className={styles.locationContent}>
              <h2>
                Location
              </h2>
              <p>
                You can find us next to Marina just below the Hotel Maritimo
              </p>
              <a href="https://www.google.com/maps/dir//Parasailing+Makarska,+Put+cvita%C4%8Dke+2A,+21300,+Makarska,+Croatia/@43.302012,17.008669,16z/data=!4m9!4m8!1m0!1m5!1m1!1s0x134aee78dac12f6b:0x926a2c8991126342!2m2!1d17.008989!2d43.302124!3e0?hl=en-US">Get directions</a>
              <div className={styles.images}>
                <div id="map">
                <MyMapComponent
                  // googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDgRZrn2yVK_R9tzJPtJ7YeHJPvUTPp1I4&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                />
                </div>
                <div>
                  <Img
                    fluid={data.locationImage.childImageSharp.fluid}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    alt="Makarska parasailing location"
                    style={{ height: '100%' }}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      />
    )
  }
}

export default Location;
