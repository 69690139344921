import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as analytics from '../helpers/analytics';

const TALogo = () => (
  <StaticQuery query={graphql`
    query {
      ta: file(relativePath: { eq: "ta-choice-2020.png" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `}
   render={data => {
     const imageData = data.ta.childImageSharp.fixed
     return (
      <a
        href="https://www.tripadvisor.com/Attraction_Review-g303814-d10540146-Reviews-Parasailing_Makarska-Makarska_Split_Dalmatia_County_Dalmatia.html"
        onClick={() => analytics.trackOutboundLink('Tripadvisor profile')}
        rel="noopener noreferrer"
        target="_blank"
        >
        <Img
          fixed={imageData}
          alt="Tripadvisor Travelers Choice 2020"
        />
      </a>
     )
   }
   }
  />
)

export default TALogo
