import React from 'react';
import * as analytics from '../helpers/analytics'
import styles from './Footer.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <div>
      Copyright &copy; Parasailing Makarska
    </div>
    <p>
      Designed and developed by <a href="http://mnikolaus.me" onClick={() => analytics.trackOutboundLink('mnikolaus.me')}>MN</a>
    </p>
  </footer>
);

export default Footer;