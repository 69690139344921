import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import About from '../components/About';
import Video from '../components/Video';
import VideoModal from '../components/VideoModal';
import Services from '../components/Services';
import Pricing from '../components/Pricing';
import Location from '../components/Location';
import Gallery from '../components/Gallery';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import TALogo from '../components/TALogo';
import * as analytics from '../helpers/analytics';
import '../styles/globals.scss'
import styles from './index.module.scss'
import Disclaimer from "../components/Disclaimer"

const BackgroundSection = ({ className }) => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "parasailing-cover.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
   render={data => {
     const imageData = data.desktop.childImageSharp.fluid
     return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#1A74B1`}
          style={{ height: '100%', position: 'absolute', zIndex: '-1', top: '0', left: '0', width: '100%' }}
        />
     )
   }
   }
  />
)

class IndexPage extends Component {
  constructor() {
    super();
    this.state = {
      showVideoModal: false,
      showMobileMenu: false,
    };
  }
  componentDidMount() {
    analytics.init();
    analytics.trackPageview('/');
    if (typeof window !== 'undefined') {
      require('smooth-scroll')('[data-scroll]', {
        easing: 'easeInOutCubic',
        speed: 300,
        speedAsDuration: true,
        updateURL: false
      });
    }
  }

  render() {
    const { showMobileMenu, showVideoModal } = this.state;
    return (
      <Layout
        isMenuOpen={showMobileMenu}
        openMenu={() => {
          this.setState({ showMobileMenu: true });
          analytics.trackEvent({ category: 'navigation', action: 'open' });
        }}
        closeMenu={() => this.setState({ showMobileMenu: false })}
      >
        <SEO
          title="Parasailing Makarska - lead provider of water sports in Makarska"
          description=" We provide parasailing services at highest standard for more than 20 years and strive to give our customers unforgetable holiday experience."
        />
        <div className={styles.heroSection}>
          <BackgroundSection />
          <div className={styles.heroSectionContent}>
            <TALogo />
            <h1>Fly <span>high</span> into the sky</h1>
            <p>
              with leading provider of water sports in Makarska
            </p>
            <div className={styles.ctaButtons}>
              <button
                className="button transparent"
                onClick={() => {
                  analytics.trackEvent({ category: 'video', action: 'click', label: 'hero section' });
                  this.setState({ showVideoModal: true });
                }}
              >
                View video
              </button>
              <a className="button" data-scroll href="#contact" onClick={() => {
                analytics.trackEvent({ category: 'reservation', action: 'click', label: 'hero section' });
              }}>
                Make reservation
              </a>
            </div>
          </div>
        </div>
        <About />
        <Video
          openVideo={() => {
            analytics.trackEvent({ category: 'video', action: 'click', label: 'video section' });
            this.setState({ showVideoModal: true });
          }}
        />
        <Services />
        <Pricing />
        <Location />
        <Gallery />
        <Disclaimer />
        <Contact />
        <Footer />
        {
          showVideoModal &&
          <VideoModal onClose={() => this.setState({ showVideoModal: false })} />
        }
      </Layout>
    )
  }

}

export default IndexPage
