import React from 'react';
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styles from './Services.module.scss'

const Services = (props) => (
  <StaticQuery
    query={
      graphql`
        query {
          service1: file(relativePath: { eq: "service-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          service2: file(relativePath: { eq: "service-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          service3: file(relativePath: { eq: "service-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }`
      }
    render={data => (
      <section className={styles.servicesContainer} id="services">
        <h2>
          Services
        </h2>
        <p>
          Our water sports center gives you opportunity to experience something slightly different on your vacation. From relaxing and joyfull flying on parasailing to a bit extreme tube driving. Our main and only goal is our customers satisfaction.
        </p>
        <div className={styles.service}>
          <div>
            <Img
              fluid={data.service1.childImageSharp.fluid}
              alt="Parasailing"
              style={{ minHeight: '200px', minWidth: '200px' }}
            />
          </div>
          <div>
            <h3>Parasailing</h3>
            <p>
              Parasailing is one of the most popular leisure activities! You always wanted to both feel free like a bird and enjoy the wonderful view at the sea? However, you don't want to do this alone, but rather with a company? If so, Parasailing is the perfect thing for you!
              Enjoy beautiful view over the Adriatic Sea on Makarska Riviera with our special big smile parachute. (200kg maximum)
            </p>
          </div>
        </div>
        <div className={styles.service}>
          <div>
            <Img
              fluid={data.service2.childImageSharp.fluid}
              alt="Water sports"
              style={{ minHeight: '200px', minWidth: '200px' }}
            />
          </div>
          <div>
            <h3>Water sports</h3>
            <p>
              If you are looking for high speed and adrenaline on water, you are at the right place. Try tubing in a group of 3 to 5 persons, you will not regret it.
            </p>
          </div>
        </div>
        <div className={styles.service}>
          <div>
            <Img
              fluid={data.service3.childImageSharp.fluid}
              alt="Taxi services"
              style={{ minHeight: '200px', minWidth: '200px' }}
            />
          </div>
          <div>
            <h3>Taxi services</h3>
            <p>
              We do taxi services to our nearest islands of Brač and Hvar. We can take up to 8 people and we are the right choice if you want to get to your destination fast.
            </p>
          </div>
        </div>
      </section>
    )}
  />
)

export default Services;
