import React from 'react'
import styles from './Disclaimer.module.scss'

const Disclaimer = () => (
  <section id="location" className={styles.disclaimerContainer}>
    <div className={styles.disclaimerContent}>
      <h2>
        Parasailing policies
      </h2>
      <div>
        <h4>
          Weather conditions
        </h4>
        <p>
          Parasail flights depend entirely on the weather. Weather is the biggest variable with parasailing, to be specific wind is the biggest factor. It’s the ‘Parasail Captain’s Call’ on determining if weather conditions will permit the parasailing.
        </p>
        <h4>
          Health restrictions
        </h4>
        <p>
          If you are PREGNANT, have a HEART CONDITION, BAD BACK or any other physical conditions that will be aggravated by the parasail flight, in the name of safety for you, other participants and our crew, the staff has the right to determine if an individual is fit to participate in parasailing activity. We want everybody to have the best experience possible.
        </p>
        <h4>
          Weight restrictions
        </h4>
        <p>
          It is the ‘Parasail Captain’s Call’. We always do our best to accommodate your request on how and who you fly with. 
          However, SAFETY is our number one priority. The parasail requires a certain amount of weight to perform a safe flight. 
          The weight parameters may change at the time of your ride due to changing wind and ocean conditions. 
          Parasailing is a dynamic activity. For this reason, small children or lighter passengers may have to fly with an adult or larger passenger. 
          Again in the end, it is  the ‘Parasail Captain’s Call’. Minimum weight for a parasail flight is 50kg. Maximum weight is 200kg. This would be the maximum combined weight for 2 or 3 people flying together. Again, these parameters may change at time of ride.
        </p>
        <h4>
          Lost or damaged property
        </h4>
        <p>
          Parasailing Makarska is not responsible for any lost or damaged property you take with you on the parasailing, taking things like mobile phones, sunglasses, keys, money, etc. with you is at your own risk.
        </p>
      </div>
    </div>
  </section>
)

export default Disclaimer
