import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import * as analytics from '../helpers/analytics';
import styles from './Contact.module.scss'

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: {
          extension: {eq: "svg"},
          name: { regex: "/icons_*/" }
        }) {
          edges {
            node {
              name,
              publicURL
            }
          }
        }
      }      
    `}
    render={data => (
      <section id="contact" className={styles.contactContainer}>
        <h2>
          Contact
        </h2>
        <p>
          For reservations and inquiries contact us on email, phone or Facebook.
        </p>
        <div className={styles.contactButtons}>
          <a href="mailto:parasailingma@gmail.com" target="_blank" rel="noopener noreferrer" onClick={() => {
            analytics.trackEvent({ category: 'contact', action: 'click', label: 'email' });
          }}>
            <div>
              <img src={data.allFile.edges.find(i => i.node.name === 'icons_email').node.publicURL} alt="Email" height="40px"/>
            </div>
            <div>
              <span>Email</span>
              <p>
                parasailingma@gmail.com
              </p>
            </div>
          </a>
          <a href="tel:+385989631918" target="_blank" rel="noopener noreferrer" onClick={() => {
            analytics.trackEvent({ category: 'contact', action: 'click', label: 'phone' });
          }}>
            <div>
              <img src={data.allFile.edges.find(i => i.node.name === 'icons_phone').node.publicURL} alt="Phone" height="40px"/>
            </div>
            <div>
              <span>Phone</span>
              <p>
                +385 (0)98 963 1918
              </p>
            </div>
          </a>
          <a href="https://www.google.com/maps/place/Parasailing+Makarska/@43.302124,17.0068003,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x926a2c8991126342!8m2!3d43.302124!4d17.008989?hl=en" target="_blank" rel="noopener noreferrer" onClick={() => {
            analytics.trackEvent({ category: 'contact', action: 'click', label: 'address' });
          }}>
            <div>
              <img src={data.allFile.edges.find(i => i.node.name === 'icons_location').node.publicURL} alt="Parasaling Makarska location" height="40px"/>
            </div>
            <div>
              <span>Address</span>
              <p>
                Put cvitačke 2a, Makarska
              </p>
            </div>
          </a>
          <a href="https://www.facebook.com/parasailingmakarska" target="_blank" rel="noopener noreferrer" onClick={() => {
            analytics.trackOutboundLink('Facebook profile');
          }}>
            <div>
              <img src={data.allFile.edges.find(i => i.node.name === 'icons_facebook').node.publicURL} alt="Parasaling Makarska on Facebook" height="40px"/>
            </div>
            <div>
              <span>Facebook</span>
              <p>
                Parasailing Makarska
              </p>
            </div>
          </a>
          <a href="https://www.instagram.com/parasailingmakarska/" target="_blank" rel="noopener noreferrer" onClick={() => {
            analytics.trackOutboundLink('Instagram profile');
          }}>
            <div>
            <img src={data.allFile.edges.find(i => i.node.name === 'icons_instagram').node.publicURL} alt="Parasaling Makarska on Instagram" height="40px"/>
            </div>
            <div>
              <span>Instagram</span>
              <p>
                parasailingmakarska
              </p>
            </div>
          </a>
          <a href="https://www.tripadvisor.com/Attraction_Review-g303814-d10540146-Reviews-Parasailing_Makarska-Makarska_Split_Dalmatia_County_Dalmatia.html" target="_blank" rel="noopener noreferrer" onClick={() => {
            analytics.trackOutboundLink('Tripadvisor profile');
          }}>
            <div>
            <img src={data.allFile.edges.find(i => i.node.name === 'icons_tripadvisor').node.publicURL} alt="Parasaling Makarska on Tripadvisor" height="40px"/>
            </div>
            <div>
              <span>Tripadvisor</span>
              <p>
                Parasailing Makarska
              </p>
            </div>
          </a>
        </div>
      </section>
    )}
  />
);

export default Contact;
