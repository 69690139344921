import React from 'react';
import Modal from 'react-modal';
import './VideoModal.scss';

const VideoModal = ({ onClose }) => (
  <Modal
    ariaHideApp={false}
    isOpen={true}
    onRequestClose={onClose}
    style={{
      overlay: {
        backgroundColor: 'rgba(0,0,0,0.9)',
        zIndex: '100'
      },
      content: {
        padding: '0',
        border: '0',
        backgroundColor: '#000',
        color: '#f5f5f5',
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      }
    }}
  >
    <header>
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
    </header>
    <iframe title="Parasailing Makarska promo video" width="100%" height="100%" src="https://www.youtube.com/embed/w_zGjQ0pPvs?autoplay=1&modestbranding=1&fs=0&constrols=0" frameForder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
  </Modal>
)

export default VideoModal;
