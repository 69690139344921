import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import * as analytics from '../helpers/analytics'
import styles from './Gallery.module.scss'

const Location = () => (
  <StaticQuery
    query={
      graphql`
        query {
          galleryImage1: file(relativePath: { eq: "parasailing-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          galleryImage2: file(relativePath: { eq: "parasailing-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          galleryImage3: file(relativePath: { eq: "parasailing-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          galleryImage4: file(relativePath: { eq: "parasailing-4.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          galleryImage5: file(relativePath: { eq: "parasailing-5.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          galleryImage6: file(relativePath: { eq: "parasailing-6.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `
    }
    render={data => (
      <section id="gallery" className={styles.galleryContainer}>
        <h2>
          Gallery
        </h2>
        <div className={styles.images}>
          <Img
            fluid={data.galleryImage1.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Makarska parasailing location"
            style={{ maxHeight: '300px' }}
          />
          <Img
            fluid={data.galleryImage2.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Makarska parasailing location"
            style={{ maxHeight: '300px' }}
          />
          <Img
            fluid={data.galleryImage3.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Makarska parasailing location"
            style={{ maxHeight: '300px' }}
          />
          <Img
            fluid={data.galleryImage4.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Makarska parasailing location"
            style={{ maxHeight: '300px' }}
          />
          <Img
            fluid={data.galleryImage5.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Makarska parasailing location"
            style={{ maxHeight: '300px' }}
          />
          <Img
            fluid={data.galleryImage6.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Makarska parasailing location"
            style={{ maxHeight: '300px' }}
          />
        </div>
        <p>
          Find more photos on our <a href="https://www.facebook.com/parasailingmakarska" target="_blank" rel="noopener noreferrer" onClick={() => analytics.trackOutboundLink('Facebook profile')}>Facebook page</a>
        </p>
      </section>
    )}
  />
)

export default Location;
