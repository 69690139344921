import React from 'react';
import { graphql, StaticQuery } from 'gatsby'
import * as analytics from '../helpers/analytics';
import styles from './About.module.scss';

const About = (props) => (
  <StaticQuery
    query={
      graphql`
        query {
          badge: file( relativePath: { eq: "tripadvisor.svg" })
          {
            publicURL
          }
        }
      `
    }
    render={data => (
      <section
        className={styles.AboutContainer}
        id="about"
      >
        <div>
          <a
            href="https://www.tripadvisor.com/Attraction_Review-g303814-d10540146-Reviews-Parasailing_Makarska-Makarska_Split_Dalmatia_County_Dalmatia.html"
            onClick={() => analytics.trackOutboundLink('Tripadvisor profile')}
            rel="noopener noreferrer"
            target="_blank"
            >
            <img
              alt="Tripadvisor badge - Makarska Parasailing"
              src={data.badge.publicURL}
              height={200}
            />
          </a>
          <span>
            Rated 5 stars
          </span>
        </div>
        <div>
          <h2>More than 20 years of experience</h2>
          <p>
            We provide services at highest standard for more than 20 years and strive to give our customers unforgetable holiday experience. Our main and only goal is our customers satisfaction.
          </p>
          <dl>
            <dt>
              Address
            </dt>
            <dd>
              Put cvitačke 2a, Makarska, Croatia
            </dd>
            <dt>
              Working hours
            </dt>
            <dd>
              Every day from 09:30 to 18:30
            </dd>
          </dl>
          {/* <p>
            Increase the fun factor and fly together with your friends or family all together above the Croatian Adriatic Sea at the famous beach of Makarska! Put on the life jacket, tighten your belt, let our boat driver tow you up and off you go! Soar up like a bird in the currents of air, up to 350 meters above the sea and enjoy the beautiful beach and sea of Makarska and its Rivera from the bird´s eye perspective. It will be an unforgettable experience!
          </p> */}
        </div>
      </section>
    )}
  />
);

export default About;
