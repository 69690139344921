import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styles from './Pricing.module.scss'

const prices = {
  300: {
    1: 60,
    2: 80,
    3: 100,
  },
  400: {
    1: 70,
    2: 90,
    3: 110,
  },
  500: {
    1: 80,
    2: 100,
    3: 120,
  },
  600: {
    1: 90,
    2: 110,
    3: 130,
  },

}

const BackgroundSection = ({ className, children }) => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "sea.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      maestro: file( relativePath: { eq: "payments-maestro.svg" })
      {
        publicURL
      }
      mastercard: file( relativePath: { eq: "payments-mastercard.svg" })
      {
        publicURL
      }
      vpay: file( relativePath: { eq: "payments-vpay.svg" })
      {
        publicURL
      }
      visa: file( relativePath: { eq: "payments-visa.svg" })
      {
        publicURL
      }
      visa2: file( relativePath: { eq: "payments-visa2.svg" })
      {
        publicURL
      }
    }
  `}
   render={data => {
     const imageData = data.desktop.childImageSharp.fluid
     return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#1A74B1`}
        >
          <div className={styles.pricesContent}>
            <h2>Pricing</h2>
            <h4>Parasailing</h4>
            <table className={styles.parasailingTable}>
              <thead>
                <tr>
                  <td>
                    Person
                  </td>
                  <td>
                    300 m
                  </td>
                  <td>
                    400 m
                  </td>
                  <td>
                    500 m
                  </td>
                  <td>
                    600 m
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    I
                  </td>
                  <td>
                    {prices[300][1]} €
                  </td>
                  <td>
                    {prices[400][1]} €
                  </td>
                  <td>
                    {prices[500][1]} €
                  </td>
                  <td>
                    {prices[600][1]} €
                  </td>
                </tr>
                <tr>
                  <td>
                    II
                  </td>
                  <td>
                    {prices[300][2]} €
                  </td>
                  <td>
                    {prices[400][2]} €
                  </td>
                  <td>
                    {prices[500][2]} €
                  </td>
                  <td>
                    {prices[600][2]} €
                  </td>
                </tr>
                <tr>
                  <td>
                    III
                  </td>
                  <td>
                    {prices[300][3]} €
                  </td>
                  <td>
                    {prices[400][3]} €
                  </td>
                  <td>
                    {prices[500][3]} €
                  </td>
                  <td>
                    {prices[600][3]} €
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ color: '#fff', fontSize: 14, marginLeft: 10, marginTop: -10 }}>* Boating 15 €</p>
            <h4>
              Big mable
            </h4>
            <table>
              <tbody>
                <tr>
                  <td>
                    15€ / per person (3-7 person)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>
              Paddle board
            </h4>
            <table>
              <tbody>
                <tr>
                  <td>
                    2h
                  </td>
                  <td colSpan={2}>
                    15€
                  </td>
                </tr>
                <tr>
                  <td>
                    whole day
                  </td>
                  <td colSpan={2}>
                    30€
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>
              Taxi service
            </h4>
            <table>
              <tbody>
                <tr>
                  <td>
                    160€ / per hour (max 8 person)
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>
              Rent a GoPro
            </h4>
            <table>
              <tbody>
                <tr>
                  <td>
                    30€
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.paymentsContainer}>
              <p>
                We accept payments with cash or with any of the following payment methods
              </p>
              <div>
                <img src={data.maestro.publicURL} alt="Maestro" height={40} />
                <img src={data.mastercard.publicURL} alt="Mastercard" height={40} />
                <img src={data.visa.publicURL} alt="Visa" height={40} />
                <img src={data.visa2.publicURL} alt="Visa" height={30} />
                <img src={data.vpay.publicURL} alt="V pay" height={40} />
              </div>
            </div>
          </div>
        </BackgroundImage>
     )
   }
   }
  />
)

const Prices = (props) => (
  <section id="pricing" className={styles.pricesContainer}>
    <BackgroundSection />
  </section>
);

export default Prices;
