import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import * as analytics from '../helpers/analytics';
import styles from './Video.module.scss'

const BackgroundSection = ({ className, children }) => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "video.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      playIcon: file( relativePath: { eq: "play.svg" })
      {
        publicURL
      }
    }
  `}
   render={data => {
     // Set ImageData.
     const imageData = data.desktop.childImageSharp.fluid
     return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#1A74B1`}
          style={{ height: '100%', position: 'absolute', zIndex: '1', top: '0', left: '0', width: '100%' }}
        >
          <img
            alt="Play button"
            src={data.playIcon.publicURL}
            height={200}
          />
        </BackgroundImage>
     )
   }
   }
  />
)

const Video = (props) => (
  <section className={styles.videoContainer} onClick={() => {
      analytics.trackEvent({ category: 'video', action: 'click', label: 'video section' });
      props.openVideo();
    }}
  >
    <BackgroundSection className={styles.bgContainer} />
  </section>
);

export default Video;
